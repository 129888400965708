:root {
  --accent: #c71a33;
  --hoveredA: #b61d34;
  --secondary: #151518;
  --greyText: rgba(255, 255, 255, 0.8);
  --dimText: rgba(255, 255, 255, 0.5);
  --bar: #1e1c1c;
  --mainBg: 14, 14, 17;
  --green: #12d460;
  --progressBarFill: rgb(94, 91, 91);
}

.noselect {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}

html,
body {
  overflow-x: hidden;
  width: 100%;
  position: relative;
  z-index: 0;
  background: #09070a;
}
#root {
  height: 100%;
}
body {
  font-size: 17px;
  color: #ffffff;

  font-family: chakra-petch, sans-serif;
  font-weight: 400;
  line-height: 23px;
}

h1 {
  text-align: center;
}

h3 {
  line-height: 40px;
}

#overlay {
  position: fixed; /* Sit on top of the page content */
  display: none; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4); /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
  z-index: 2;
}

.ghostBg::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.flexRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flexCol {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

button,
a.button {
  padding: 17px 22px 17px 16px;
  text-transform: uppercase;
  border: none;
  display: flex;
  align-self: center;
  font-size: 16px;
  justify-content: space-around;
  color: #ffffff;
  height: 56px;
  cursor: pointer;
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  position: relative;
  z-index: 156;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 0.06em;
}

button.transparent {
  background-color: transparent;
  border: none;
}

button.accent,
a.button.accent {
  /* background: var(--accent); */
  background: var(--accent);
}

button.accent:hover,
a.button.accent:hover {
  background: var(--hoveredA);
}

.createAccountButton {
  margin-top: 38px;
  background-color: #1e1e1f;
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.4);
  font-family: "chakra-petch";
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  pointer-events: none;
}
.createAccountButtonEnable {
  background: #c71a33;
  color: #ffffff;
  letter-spacing: 0.06em;
  pointer-events: visible;
}

.createAccountButtonEnable:hover {
  filter: brightness(0.95);
}

.termsCheckBox {
  text-decoration: underline !important;
  color: #fff;
}

.termsCheckBox:hover {
  color: #fff;
  text-decoration: none !important;
}

button.secondary,
a.button.secondary {
  background-color: var(--secondary);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

button.secondary:hover,
a.button.secondary:hover {
  background-color: #353232;
}

.impo:hover {
  background-color: #353232 !important;
}

button.accent > svg {
  margin-right: 14px;
}

ul.links li a,
ul.links li,
ul.links a {
  text-decoration: none;
  color: var(--greyText);
  font-size: 13px;
}

.controls.flexRow {
  cursor: pointer;
}

.customModal {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgb(var(--mainBg));
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  padding-top: 124px;
  animation: fadein 0.2s ease;
  user-select: none;
  z-index: 9999;
}

body.showModal {
  height: 100vh;
  overflow: hidden;
}

input {
  background: rgba(22, 23, 24, 0.89);
  border: 1px solid rgba(255, 255, 255, 0.3);
  width: 100%;
  padding: 12px 20px;
  color: var(--greyText);
}

input:focus,
input:active {
  outline: none;
}

input[type="checkbox"] {
  padding: 0;

  width: 100%;
  height: 100%;
}

.checkBoxWrapper {
  position: relative;
  width: 26px;
  height: 26px;
}

.textForCheckbox {
  opacity: 0.8;
}

.checkBoxWrapper::after {
  content: "";
  z-index: 3;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgb(var(--mainBg));
  border: 1px solid rgba(255, 255, 255, 0.3);
  cursor: pointer;
}

.checkBoxWrapper.checked::before {
  content: url("../../assets/svgs/singup/vCheckbox.svg");
  z-index: 4;
  position: absolute;
  width: 65%;
  height: 65%;
  cursor: pointer;
}
.disableEmailButton {
  pointer-events: none;
}

.enableEmailButton {
}

.lastCheckBox {
  margin-bottom: 38px;
}
.withError {
  position: relative;
}

.withError .errorMessage {
  display: none;
  position: absolute;
  bottom: 0;
  color: red;
  left: 0;
  transform: translateY(120%);
}

.withError.active .errorMessage {
  display: inline;
}

.emptyBlock {
  height: 1px;
  width: 100%;
  margin-bottom: 30px;
}

button.completed {
  color: var(--green);
  background: rgba(18, 242, 108, 0.02);
}

.small-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 476px;
  height: 453px;
  background: #18181a;
  border: 1px solid #282829;
  z-index: 99999;
}

.modal-header {
  display: flex;
  position: relative;
  text-align: center;
  margin-bottom: 12px;
  margin-left: auto;
  margin-right: -30px;
}

.small-modal > img {
  position: absolute;
  right: 10px;
  top: 10px;
  width: 20px;
  height: 20px;
}

.modal-body ul li {
  padding-left: 26px;
  padding-right: 26px;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 120px;
  background: #18181a;
  border: 1px solid #2e2e2f;
  margin-top: 10px;
  cursor: pointer;
}

.modal-body ul li span {
  margin: 0 auto;
  margin-top: 12px;
}

.modal-body > span {
  margin-top: 15px;
  font-size: 14px;
  color: var(--dimText);
}

.modal-body ul li:hover {
  background: #1f1f20;
}

.modal-body ul li img {
  width: 43.13px;
  height: 40.04px;
}

.fa-twitter {
  background: #55acee;
  color: white;
  padding: 5px 10px;
  height: 40px;
  font-size: 15px;
}

.modal-body {
  display: flex;
  flex-direction: column;
  padding-left: 24px;
  padding-right: 24px;
}

.popupHeader {
  font-size: 18px;
  text-align: center;
  font-family: "chakra-petch";
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #343436;
  position: relative;
  z-index: 80;
}

.popupHeader img {
  margin-left: auto;
  margin-right: 21px;
  margin-bottom: 5px;
  cursor: pointer;
}

.newBackground {
  background-color: #151518 !important;
}

.lds-ellipsis {
  display: inline-block;

  width: 30px;
  height: 30px;
}
.lds-ellipsis div {
  position: absolute;
  top: 13px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: rgb(131, 129, 129);
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}

.links svg:hover path {
  fill: red;
}
.links svg:hover rect {
  stroke: red;
}
.links svg rect {
  stroke: transparent;
}
